export const cards = [
    {
        name: "Visa",
        length: "13,16",
        prefixes: "4",
    },
    {
        name: "MasterCard",
        length: "16",
        prefixes: "51,52,53,54,55",
    },
    {
        name: "DinersClub",
        length: "14,16",
        prefixes: "36,38,54,55",
    },
    {
        name: "CarteBlanche",
        length: "14",
        prefixes: "300,301,302,303,304,305",
    },
    {
        name: "AmEx",
        length: "15",
        prefixes: "34,37",
    },
    {
        name: "Discover",
        length: "16",
        prefixes: "6011,622,64,65",
    },
    {
        name: "JCB",
        length: "16",
        prefixes: "35",
    },
    {
        name: "enRoute",
        length: "15",
        prefixes: "2014,2149",
    },
    {
        name: "Solo",
        length: "16,18,19",
        prefixes: "6334,6767",
    },
    {
        name: "Switch",
        length: "16,18,19",
        prefixes: "4903,4905,4911,4936,564182,633110,6333,6759",
    },
    {
        name: "Maestro",
        length: "12,13,14,15,16,18,19",
        prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
    },
    {
        name: "VisaElectron",
        length: "16",
        prefixes: "4026,417500,4508,4844,4913,4917",
    },
    {
        name: "LaserCard",
        length: "16,17,18,19",
        prefixes: "6304,6706,6771,6709",
    }
];