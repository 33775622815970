import React from 'react';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

//Components
import DesktopMenu from './DesktopMenu';
import BurgerMenu from './BurgerMenu';
import PageScroller from "../../components/page_scroller";


//Assets
import './index.scss';
import burger from 'assets/icons/burger.svg';
import logo from 'assets/logos/react.svg';

//routing constants
import Routes from 'constants/routes';

//Actions
import Actions from 'redux/actions';

/* example menu data from api */
const menuList = [
    {
        text: 'ראשי',
        route: Routes.ROOT
    },
    {
        text: 'לינק מוגן',
        route: Routes.TEST_PROTECTED
    },
    {
        text: 'צור קשר',
        route: Routes.CONTACT
    },
    {
        text: 'Docs',
        route: Routes.DOCS
    },
];

function Header() {

    const deviceState = useSelector(store => store.deviceState);

    const dispatch = useDispatch();

    const pageScrollerState = useSelector(store => store.pageScrollerState);

    const handleBurgerClick = () => {
        dispatch(Actions.setBurger(true));
    }

    const { pathname } = useLocation();

    const [shownBtn, setShownBtn] = useState(null)

    useEffect(() => {
        setShownBtn(pageScrollerState && pathname === Routes.DOCS_HEADER.page_scroller)
    }, [pageScrollerState, Routes.DOCS_HEADER.page_scroller])

    return (
        <header className='header-wrapper'>

            <PageScroller />

            <div className="header">
                <div className="nav-container">
                    {deviceState.isMobile &&
                        <div className="burger-icon" onClick={handleBurgerClick}>
                            <img src={burger} alt="" />
                        </div>
                    }
                    {deviceState.notMobile && <DesktopMenu data={menuList} />}
                    {deviceState.isMobile && <BurgerMenu data={menuList} />}
                    {shownBtn && <button className="header-btn-example">דוגמה</button>}
                </div>
                <img src={logo} className="logo" alt="" />
            </div>
        </header>
    )
}

export default Header;