/* documentation */
const BASE = {
    ROOT: '/',
    ABOUT: '/אודות',
    CONTACT: '/צור_קשר',
    DOCS: '/docs',
    DOCS_COMPONENTS: '/docs/components',
    DOCS_TOOLS: '/docs/tools',
    TEST_PROTECTED: '/protected'
}

const ROUTES = {
    ROOT: BASE.ROOT,
    TEST_PROTECTED: BASE.TEST_PROTECTED,
    ABOUT: BASE.ABOUT,
    CONTACT: BASE.CONTACT,
    DOCS: BASE.DOCS,
    DOCS_TOOLS: BASE.DOCS_TOOLS,
    DOCS_COMPONENTS: BASE.DOCS_COMPONENTS,
    DOCS_DEVICE: `${BASE.DOCS_TOOLS}/device`,
    DOCS_APIMANAGER: `${BASE.DOCS_TOOLS}/api_manager`,
    DOCS_QUEUEMANAGER: `${BASE.DOCS_TOOLS}/queue_manager`,
    DOCS_REQUESTS: `${BASE.DOCS_TOOLS}/requests`,
    DOCS_SCROLL: `${BASE.DOCS_COMPONENTS}/scroll`,
    DOCS_META: `${BASE.DOCS_COMPONENTS}/meta`,
    DOCS_COLLAPSE: `${BASE.DOCS_COMPONENTS}/collapse`,
    DOCS_CHECKBOX: `${BASE.DOCS_COMPONENTS}/checkbox`,
    DOCS_ANIMATED_INPUT: `${BASE.DOCS_COMPONENTS}/animated_input`,
    DOCS_ANIMATED_TEXTAREA: `${BASE.DOCS_COMPONENTS}/text_area`,
    DOCS_SELECT: `${BASE.DOCS_COMPONENTS}/select`,
    DOCS_RADIOBUTTON: `${BASE.DOCS_COMPONENTS}/radiobutton`,
    DOCS_TIMER: `${BASE.DOCS_COMPONENTS}/timer`,
    DOCS_SMARTIMAGE: `${BASE.DOCS_COMPONENTS}/smart_image`,
    DOCS_AUTOCOMPLETE: `${BASE.DOCS_COMPONENTS}/autocomplete`,
    DOCS_CONTAINERS: `${BASE.DOCS}/containers`,
    DOCS_LOADERS: {
        screen_loader: `${BASE.DOCS_COMPONENTS}/screen_loader`,
        component_loader: `${BASE.DOCS_COMPONENTS}/component_loader`
    },
    DOCS_POPUPS: {
        basic_popup: `${BASE.DOCS_COMPONENTS}/basic_popup`,
        two_action_popup: `${BASE.DOCS_COMPONENTS}/two_action_popup`
    },
    DOCS_HEADER: {
        desktop_menu: `${BASE.DOCS_COMPONENTS}/header/desktop-menu`,
        burger_menu: `${BASE.DOCS_COMPONENTS}/burger-menu`,
        page_scroller: `${BASE.DOCS_COMPONENTS}/page-scroller`
    },
};

export default ROUTES;
