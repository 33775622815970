import ApiManager from '../index';
import Actions    from "redux/actions";
import store      from "../../redux";

class Api extends ApiManager{

    constructor(){
         if(!Api.instance) {
             super();
             Api.instance = this;
         }

         return  Api.instance = this;
    }

    getHostUrl = (props = {}) =>{
        const onSuccess = (response) =>{
            let api = {
                base_url: response.data.host,
                get_methods:response.data.get_methods,
                get_url: response.data.get_url
            }
            this._updateApiParams(api);
        }
        return this._execute(props, 'getHostUrl', onSuccess);
    };

    generalDeclaration = (props = {}) =>{
        const onSuccess = (response) => {
            store.dispatch(Actions.setGd(response.data));
        };

        return this._execute(props, 'generalDeclaration', onSuccess);
    };
}

const instance = new Api();
Object.freeze(instance);
export default instance;