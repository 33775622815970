import AppConfig from 'app/config/AppConfig';
import {cards} from '../cards/cards.js';

//returns full path of asset
export function getPath(path) {
    let Config = new AppConfig();
    return (Config.debug_mode ? Config.media_path + path : path);
}

//returns full path of asset
export function getMediaPath(path) {
    let Config = new AppConfig();
    return Config.media_path + path;
}

//check for empty objects
export function notEmptyObject(obj) {
    let empty = true;
    if(obj) {
        if(Object.keys(obj).length !== 0 && obj.constructor === Object) {
            empty = false;
        }
    }
    return empty;
}

//Convert Object to sorted array by order_num
export function convertToSortedArray(obj) {
    let sortedArr = Object.keys(obj).map(key => obj[key]);
    sortedArr = sortedArr.sort((a,b) => { return a.order_num - b.order_num });
    return sortedArr;
}

//query browser for user location
export function getUserGeoLocation(onSuccess, onFailure) {
    if ("geolocation" in navigator) {
        // check if geolocation is supported/enabled on current browser
        navigator.geolocation.getCurrentPosition (
            function success(position) {
                // for when getting location is a success
                onSuccess(position);
            },
            function error(error_message) {
                // for when getting location results in an error
                // console.error('An error has occured while retrieving location', error_message);
                onFailure(error_message);
            });
    } else {
        // geolocation is not supported
        // get your location some other way
        console.log('geolocation is not enabled on this browser');
    }
}

//calculate distance in Km between 2 gps sets
export function calcDistance(p1, p2) {
    var R = 6371; // Radius of the earth in km
    var dLat = toRad((p1.lat - p2.lat)); // Javascript functions in radians
    var dLon = toRad((p1.lng - p2.lng));
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(p2.lat)) * Math.cos(toRad(p1.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return parseInt(d);
}
function toRad(num) {
    return num * Math.PI / 180;
}

//generate unique IDs
export function generateUniqueId(length = 16) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}

export const validateCardNumber = (number, cardCompany) => {
    //Check if the number contains only numeric value
    //and is of between 13 to 19 digits
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(number) || !isCardNumberValidByCompany(number)){
        return false;
    }

    return luhnCheck(number);
};

const isCardNumberValidByCompany = (number) => {
    const company_name = findCardCompany(number);
    const company = cards.find(obj => {
        return obj.name === company_name
    });
    let lengthValid = false;
    if(company) {
        const lengths = company.length.split(",");
        for (let j = 0; j < lengths.length; j++) {
            if (number.length === parseInt(lengths[j])) {
                lengthValid = true;
                break;
            }
        }
    }
    return lengthValid;
};

// luhn’s algorithm for validation of credit card
const luhnCheck = (val) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
        let calc = 0;
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(val.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
            checksum = checksum + 1;
            calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j == 1) {
            j = 2;
        } else {
            j = 1;
        }
    }

    //Check if it is divisible by 10 or not.
    return (checksum % 10) == 0;
};

export const findCardCompany = (cardnumber) => {

    for(let i = 0; i < cards.length; i++){
        const prefix = cards[i].prefixes.split(",");

        for (let j = 0; j < prefix.length; j++) {
            const exp = new RegExp ("^" + prefix[j]);
            if (exp.test (cardnumber)) {
                return cards[i].name;
            }
        }
    }

    return "";
};