import React, {Component, useEffect} from 'react';
import {useState}               from "react"

import {useDispatch, useSelector} from "react-redux";
import Actions from 'redux/actions';
import { findCardCompany } from 'app/functions';
import Validate from "app/validation/validation";
import AnimatedInput from "../animated_input";
import Select from "../select";
import Checkbox from "../checkbox";
import './index.scss'
import checkedImage from "assets/checkbox/checked-checkbox.svg";
import emptyImage from "assets/checkbox/empty-checkbox.svg";


const PaymentForm = () => {

    const dispatch = useDispatch();
    const YEARS_LENGTH = 10;
    const MONTHS_LENGTH = 12;
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const form = useSelector(store => store.paymentForm);
    const [cardCompany, setCardCompany] = useState("");
    const [firstTry, setFirstTry] = useState(true);
    const [formState, setFormState] = useState({
        card_number: {
            valid: false,
            pattern: "^[0-9]*$",
            rules: ["not_empty", "credit_card"],
            errMsg: "",
        },
        expiration_year: {
            valid: false,
            rules: ["required_select"],
            errMsg: "",
        },
        expiration_month: {
            valid: false,
            rules: ["required_select"],
            errMsg: "",
        },
        cvv: {
            valid: false,
            pattern: "^[0-9]*$",
            rules: ["not_empty"],
            errMsg: "",
        },
        personal_id: {
            valid: false,
            pattern: "^[0-9]*$",
            rules: ["not_empty", "id"],
            errMsg: "",
        },
        keep_cc_details: {
            valid: false,
            rules: ["no_validation"],
            errMsg: "",
        }
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const yearsArr = getRange(currentYear, currentYear + YEARS_LENGTH).map((e) => { return {id: e, text: e.toString()}});
        const monthsArr = getRange(1, MONTHS_LENGTH).map((e) => { return {id: e, text: e.toString()}});
        setYears(yearsArr);
        setMonths(monthsArr);
    }, []);

    const getRange = (start, stop, step = 1) => {
        return Array.from({ length: (stop - start) / step + 1}, (_, i) => {
            return start + (i * step);
        });
    };

    const handleInputChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        if(name === 'card_number') {
            setCardCompany(findCardCompany(val));
        }
        if (e.target.type === "checkbox") {
            val = e.target.checked;
        }
        if(formState[name].hasOwnProperty('pattern')) {
            const regex = new RegExp(formState[name].pattern);
            if (!regex.test(val)) {
                console.log(val)
                return;
            }
        }
        let validationObj = Validate(val, formState[name].rules);

        let new_state = { ...formState };
        new_state[name].valid = validationObj.valid;
        new_state[name].errMsg = validationObj.msg;

        setFormState(new_state);
        updateForm({ [name]: val });
    };

    const handleSelectChange = (name, id) => {
        updateForm({ [name]: id });
    };

    const updateForm = (payload) => {
        dispatch(Actions.updatePaymentForm(payload));
    };

    const showError = (field) => {
        return !firstTry && !formState[field].valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let form_valid = true;
        let new_state = { ...formState };
        let validationObj;

        for (let field in formState) {
            validationObj = Validate(
                form[field],
                formState[field].rules
            );

            new_state[field].valid = validationObj.valid;
            new_state[field].errMsg = validationObj.msg;

            if (!validationObj.valid) {
                form_valid = false;
            }
        }

        setFirstTry(false);
        setFormState(new_state);

        if (form_valid) {
            console.log("submit");
        }
    };

    return(
        <section className="payment-form-wrapper">
        <div className="form-wrapper">
            <form name="contact-form">

                <AnimatedInput
                    className="field"
                    value={form.card_number}
                    name={"card_number"}
                    placeholder={"מספר כרטיס"}
                    maxlength={32}
                    autocomplete={false}
                    onChange={handleInputChange}
                    showError={showError("card_number")}
                    errorMessage={formState.card_number.errMsg}
                />
                <div className="field">
                {cardCompany}
                </div>
                <Select
                    className="field"
                    label="שנה"
                    name="expiration_year"
                    options={years}
                    onChange={handleSelectChange}
                    mobileNativeSelect={true}
                    selectedId={form.expiration_year}
                    showError={showError("expiration_year")}
                    errorMessage={formState.expiration_year.errMsg}
                />
                <Select
                    className="field"
                    label="חודש"
                    name="expiration_month"
                    options={months}
                    onChange={handleSelectChange}
                    mobileNativeSelect={true}
                    selectedId={form.expiration_month}
                    showError={showError("expiration_month")}
                    errorMessage={formState.expiration_month.errMsg}
                />
                <AnimatedInput
                    className="field"
                    value={form.cvv}
                    name={"cvv"}
                    placeholder={"CVV"}
                    maxlength={4}
                    autocomplete={false}
                    onChange={handleInputChange}
                    showError={showError("cvv")}
                    errorMessage={formState.cvv.errMsg}
                />
                <AnimatedInput
                    className="field"
                    value={form.personal_id}
                    name={"personal_id"}
                    placeholder={"תעודת זהות"}
                    maxlength={10}
                    autocomplete={false}
                    onChange={handleInputChange}
                    showError={showError("personal_id")}
                    errorMessage={formState.personal_id.errMsg}
                />
                <Checkbox
                    className="field"
                    type="checkbox"
                    name="keep_cc_details"
                    id="keep_cc_details"
                    label="הגדירו כקבוע"
                    value={form.keep_cc_details}
                    onChange={handleInputChange}
                    checkedImage={checkedImage}
                    emptyImage={emptyImage}
                    showError={showError("keep_cc_details")}
                    errorMessage={formState.keep_cc_details.errMsg}
                />
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="submit-btn teal-button"
                >
                    PAY
                </button>

            </form>
        </div>
        </section>
    );

};

export default PaymentForm;