import React from 'react';
import {useSelector} from 'react-redux';

import PaymentForm from "../../components/forms/payment_form";
import './index.scss';

const Payment = (props) => {
    const Text = useSelector((store) => store.gd?.translationsArr) ?? 'PAYMENT FORM';
    return (
        <div className="form-page-container">
            <h1 className={"title"}>{Text}</h1>
            <PaymentForm/>
        </div>
    );
};

export default Payment;
