import React, {Component, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import Api                from 'api/requests';
import {Navigate, Route, Routes} from 'react-router-dom';

//import components
import Header      from 'containers/header';
import MetaTags    from 'components/meta_tags';
import ScreenLoader      from './components/common/loaders/screen';
import ScrollToTop from 'components/routing/scroll_top';

//Import containers
import Popups  from 'popups';
import {
    Contact,
    ContentPage,
    Home,
    Payment,
    Page404,
    ProtectedRoute
} from "./containers";

//remove in production
import Docs from 'dev/docs';
import AllComponents from 'dev/all-components';

//css
import 'styles/app.scss';
import 'styles/fonts.scss';

//routing constants
import routes from 'constants/routes';
import * as TopRoutes from "./constants/routes";
import Tools from "./dev/docs/tools";
import Components from "./dev/docs/components";
import Device from "./dev/docs/tools/device";
import ApiManager from "./dev/docs/tools/api_manager";
import QueueManager from "./dev/docs/tools/queue_manager";
import Requests from "./dev/docs/tools/requests";
import Scroll from "./dev/docs/components/scrolltop";
import Meta from "./dev/docs/components/meta";
import ComponentLoader from "./dev/docs/components/loaders/component";
import BasicPopup from "./dev/docs/components/popups/basic";
import TwoActionPopup from "./dev/docs/components/popups/two_action";
import Collapse from "./dev/docs/components/collapse";
import AnimatedInput from "./dev/docs/components/animated_input";
import AnimatedTextArea from "./dev/docs/components/animated_textarea";
import Checkbox from "./dev/docs/components/checkbox";
import Select from "./dev/docs/components/select";
import {SmartImagePage} from "./dev/docs/components/smart-image";
import RadioButton from "./dev/docs/components/radiobutton";
import Timer from "./dev/docs/components/timer";
import Autocomplete from "./dev/docs/components/autocomplete";
import DesktopMenu from "./dev/docs/components/header/desktop_menu";
import BurgerMenu from "./dev/docs/components/header/burger_menu";
import PageScroller from "./dev/docs/components/header/page_scroller";
import Documentation from "dev/docs";
import PrivateRoute from "./components/private-route";

const App = (props) => {
    const [initialRequestsDone, setInitialRequestsDone] = useState(true);
    const [showLoader, setShowLoader] = useState(loaderState);
    const [startingLoadingTime, setStartingLoadingTime] = useState(0);
    const deviceState = useSelector(store => store.deviceState);
    const popupsArray = useSelector(store => store.popupsArray);
    const loaderState = useSelector(store => store.loaderState);

    useEffect(() => {
        // Api.getHostUrl({config:{method:'get'}}).then((response)=>{
        //     if(response?.status){
        //         Api.generalDeclaration().then(()=>{
        //             setInitialRequestsDone(true);
        //         });
        //     }
        // });
    }, []);

    useEffect(() => {
        if (loaderState) {
            setShowLoader(loaderState);
            setStartingLoadingTime(new Date().getTime());
        } else {
            const currentTime = new Date().getTime();
            const diff = currentTime - startingLoadingTime;
            const minDelay = 2000;
            setTimeout(() => setShowLoader(loaderState),
                diff < minDelay ? minDelay - diff : 0);
        }
    }, [loaderState]);

    const showPopup  = popupsArray.length > 0;
    //let showLoader = this.props.loaderState;
    const renderPage = deviceState && initialRequestsDone;

    //template object for dynamic page routes (content pages)
    const data = [
        {path: '/content1', component: ContentPage},
        {path: '/content2', component: ContentPage},
        {path: '/content3', component: ContentPage}
    ];

    return (
        <div className="App">
            {renderPage &&
            <>

                {/* main routing table */}
                <Routes>
                    {/* regular routes */}
                    <Route path={ routes.ROOT } element={ <Payment/> }/>
                    {/*<Route path={ routes.TEST_PROTECTED} element={<PrivateRoute Container={ProtectedRoute}/>}/>*/}
                    {/*<Route path={ routes.CONTACT } element={ <Contact/> }/>*}*/}

                    {/* template for dynamic pages */}
                    {/*data.map(page =>
                        <Route key={ page.path } path={ page.path } element={ <page.component/> }/>
                    )*/}

                    {/* defaults to 404 if none are found */}
                    <Route path="*" element={ <Page404/> }/>
                </Routes>

                {/* make sure new page scroll position is at the top */}
                {/*<ScrollToTop />*/}

                {/* render meta tags from the server */}
                {/*<MetaTags />*/}
            </>
            }
            {/* main modal component */}
            { showPopup && <Popups />}

            {/* full page loader */}
            { showLoader && <ScreenLoader />}
        </div>
    );
};

export default App;
