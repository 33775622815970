import { useSelector } from 'react-redux';
import * as Routes from 'constants/routes';

import "./index.scss";

function Tool() {

    const pageScrollerState = useSelector(store => store.pageScrollerState);


    return (
        <div className="tool-wrapper with-preview docs-tools-page-scroller">
            <div className="left">
                <div className="component">
                    <div className="component-title">Header - Page scrolling</div>
                    <div className="component-usage">
                        Allows making changes in the header while scrolling the page.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">
                        <b>Location:</b>
                    </div>
                    <div className="label">
                        /components/page_scroller
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">
                        <b>Props:</b>
                    </div>
                    <div className="label">
                        options - An object with 3 keys: root, rootMargin, threshold (passed into the IntersectionObserver).
                    </div>
                    <div className="label">
                        rootMargin - The distance of the scroll before the header will change.
                    </div>
                    <div className="label">
                        callback - Call back function, receives 2 parameters: entries, observer.
                    </div>
                    <div className="label">
                        className -Name of class.
                    </div>
                </div>
                <div className="import-strip">
                    <div className="label">
                        <b>State:</b>
                    </div>
                    <div className="label">
                        pageScrollerState(boolean)
                    </div>
                </div>
                <div className="code-sample">
                    <div className="code-sample">
                        <code>{`<PageScroller />`}</code>
                    </div>
                </div>
            </div>
            <div className="right">
                {
                    !pageScrollerState&&
                    <button className="header-btn-example">דוגמה</button>
                }
            </div>
        </div>
    );
}

export default Tool;